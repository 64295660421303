import axios from 'axios'
import * as VMasker from "vanilla-masker"

export default class Suporte {
    constructor() {

        this.form = document.querySelector('#formSuporte');
        this.btnSubmitForm = document.querySelector('#btnEnviarSuporte');
        this.errorForm = document.getElementById('erroSuporte');
        this.sucessoForm = document.getElementById('sucessoSuporte');
        this.telMask = ['(99)99999-9999']; //Field

        this.cidade = document.querySelector('#cidade');
        this.cidadeField = document.querySelector('#dropdown-get-empreendimentos');
        this.produto = document.querySelector('#empreendimento');
        this.produtoField = document.querySelector('#dropdown-empreendimentos');
        this.nome = document.querySelector('#nomeSuporte');
        this.telefone = document.querySelector('#telefoneSuporte');
        this.email = document.querySelector('#emailSuporte');
        this.torre = document.querySelector('#torreSuporte');
        this.relato = document.querySelector('#relatoSuporte');
        this.events();

    }
    
    sendForm() {
      
        const dataForm = new FormData(this.form); // console.log(dataForm)
        // console.log(dataForm)
    
        axios({
            method: "post",
            url: url + "/ajax/salva_lead.php",
            data: dataForm,
            headers: {"Content-Type": "multipart/form-data"}
        })
            .then((data) => {

                console.log('Retorno: ', data);
                const dados = parseInt(data.data);
                console.log('dados', dados);

                if (dados != 3) {

                    axios({
                        method: "post",
                        url: url + "/email/form_contato.php?codigo=" + dados,
                        data: dataForm,
                        headers: {"Content-Type": "multipart/form-data"}
                    })
                        .then((dataReturnEmail) => {
                            console.log('Retorno: ', parseInt(dataReturnEmail.data));
                            this.form.reset();
                            this.btnSubmitForm.innerHTML = "Enviar"
                            this.sucessoForm.style.display = 'block';
                        })
                        .catch((dataErro) => {
                            console.warn('ERRO')
                            console.log(dataErro)
                            // this.btnSubmitForm.innerHTML = "Enviar"
                        })

                }
                

            })
            .catch(function (data) {
                console.warn('ERRO')
                console.log(data)
            })

    }

    maskfields(masks, max, event) {
        let c = event.target;
        let v = c.value.replace(/\D/g, '');
        let m = c.value.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
    }
    
    validateField() {
        
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let erros = 0;
        // console.log('Cidade', this.cidade.value);

        if (this.cidade.value.length === 0) {
            this.cidadeField.parentElement.classList.add('error-field');
            console.warn('Erro no campo: cidade');
            erros++;
        } else {
            this.cidadeField.parentElement.classList.remove('error-field');
        }

        if (this.produto.value.length === 0) {
            this.produtoField.parentElement.classList.add('error-field');
            console.warn('Erro no campo: produto');
            erros++;
        } else {
            this.produtoField.parentElement.classList.remove('error-field');
        }

        if (this.nome.value.length < 2) {
            this.nome.parentElement.classList.add('error-field');
            console.warn('Erro no campo: nome');
            erros++;
        } else {
            this.nome.parentElement.classList.remove('error-field');
        }

        if (this.telefone.value.length < 13) {
            this.telefone.parentElement.classList.add('error-field');
            console.warn('Erro no campo: telefone');
            erros++;
        } else {
            this.telefone.parentElement.classList.remove('error-field');
        }

        if (!this.email.value.match(mailformat)) {
            this.email.parentElement.classList.add("error-field");
            console.warn('Erro no campo: email');
            erros++;
        } else {
            this.email.parentElement.classList.remove("error-field");
        }

        if (this.torre.value.length === 0) {
            this.torre.parentElement.classList.add('error-field');
            console.warn('Erro no campo: tipo de consulta');
            erros++;
        } else {
            this.torre.parentElement.classList.remove('error-field');
        }

        if (this.relato.value.length === 0) {
            this.relato.parentElement.classList.add('error-field');
            console.warn('Erro no campo: relato');
            erros++;
        } else {
            this.relato.parentElement.classList.remove('error-field');
        }

        if (erros === 0) {
            this.btnSubmitForm.disabled = true;
            this.btnSubmitForm.innerHTML = "Aguarde Enviando..."
            this.sendForm();
            this.errorForm.style.display = 'none';
        } else {
            this.btnSubmitForm.innerHTML = "Enviar"
            this.btnSubmitForm.disabled = false;
            this.errorForm.style.display = 'block';
        }

    }

    events() {

        VMasker(this.telefone).maskPattern(this.telMask[0]);
        this.telefone.addEventListener('input', this.maskfields.bind(undefined, this.telMask, 14), false);
        this.btnSubmitForm.addEventListener('click', this.validateField.bind(this));
        
    }

}