import IMask from "imask";

export default class Forms {
    constructor() {
        this.cpfCnpjField = document.querySelectorAll(".cpfCnpjMask");
        this.phoneField = document.querySelectorAll(".telMask");
        this.dateField = document.querySelectorAll(".dateMask");
    }

    static wrongMessages() {
        let mensagens = {
                messages: {
                    missingValue: {
                        checkbox: "Este campo Ã© obrigatÃ³rio.",
                        radio: "Por favor selecione um valor.",
                        select: "Por favor selecione um valor.",
                        "select-multiple":
                            "Por favor selecione pelo menos um valor.",
                        default: "Por favor preencha este campo",
                    },
                    patternMismatch: {
                        email: "Insira um endereÃ§o de e-mail corporativo vÃ¡lido.",
                        url: "Informe uma url.",
                        number: "Informe o telefone",
                        date: "Por favor utilizar o formato DD/MM/YYYY",
                        default: "Por favor, preencha o campo com conteÃºdo vÃ¡lido.",
                    },
                    outOfRange: {
                        over: "Selecione um valor que nÃ£o seja superior a {max}.",
                        under: "Selecione um valor que nÃ£o seja inferior a {min}.",
                    },
                    wrongLength: {
                        over: "Reduza este texto para nÃ£o mais que {maxLength} caracteres. VocÃª estÃ¡ usando atualmente {length} caracteres.",
                        under: "Por favor, estenda este texto para {minLength} caracteres ou mais. VocÃª estÃ¡ usando atualmente {length} caracteres.",
                    },
                },
            };
        return mensagens;
    }

    static masks(phoneFields, dateFields, cpfCnpjFields, type) {
        if (phoneFields) {
            phoneFields.forEach(field => {
                IMask(field, {
                    mask: "(00) 00000-0000",
                });
            });
        }

        if (dateFields) {
            dateFields.forEach(field => {
                IMask(field, {
                    mask: Date,
                    pattern: "d/`m/`Y",
                    blocks: {
                        d: {
                            mask: IMask.MaskedRange,
                            from: 1,
                            to: 31,
                        },
                        m: {
                            mask: IMask.MaskedRange,
                            from: 1,
                            to: 12,
                        },
                        Y: {
                            mask: IMask.MaskedRange,
                            from: 1900,
                            to: 2090,
                        },
                    },
                });
            });
        }

        if (cpfCnpjFields) {
            cpfCnpjFields.forEach(field => {
                if (field.inputMask) {
                    field.inputMask.destroy();
                }
                if (type === 'CPF') {
                    field.inputMask = IMask(field, {
                        mask: "000.000.000-00",
                    });
                } else {
                    field.inputMask = IMask(field, {
                        mask: "00.000.000/0000-00"
                    });
                }
            });
        }
    }
}