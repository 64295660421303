import { tns } from "tiny-slider";

export default class SliderGaleria {
    constructor() {

        this.slider = document.querySelector(".sliderGaleria");
        this.thumbnails = document.querySelector(".sliderGaleriaThumbs");

       this.ready();
    }

    ready() {

        /*

        const tnsCarousel = document.querySelectorAll(".sliderGaleria");
        if (tnsCarousel && !tnsCarousel.length) return false;

        tnsCarousel.forEach(slider => {
            const sliderCurrent = tns({
                loop: false,
                container: slider,
                mouseDrag: true,
                controls: true,
                nav: true,
                autoHeight: false,
                items: 1,
                controlsText: ['<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 28.5 2 15 15.5 1" stroke="#514D4C" stroke-width="2"/></svg>', '<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5 15 15 1.5 29" stroke="#514D4C" stroke-width="2"/></svg>'],
                onInit: (sliderInstance) => {
                    sliderInstance.container.classList.remove("invisible");
                }
            });
        });
        
        const tnsCarouselThumbs = document.querySelectorAll(".sliderGaleriaThumbs");
        if (tnsCarouselThumbs && !tnsCarouselThumbs.length) return false;

        tnsCarouselThumbs.forEach(slider => {
            const sliderCurrentThumbs = tns({
                loop: false,
                container: slider,
                mouseDrag: true,
                controls: true,
                nav: true,
                autoHeight: false,
                items: 8,
                controlsText: ['<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 28.5 2 15 15.5 1" stroke="#514D4C" stroke-width="2"/></svg>', '<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5 15 15 1.5 29" stroke="#514D4C" stroke-width="2"/></svg>'],
                onInit: (sliderInstance) => {
                    sliderInstance.container.classList.remove("invisible");
                }
            });
        });

        */

        this.slider = tns({
            container: '.sliderGaleria',
            items: 1,
            autoHeight: true,
            lazyload: false,
            mouseDrag: true,
            controls: true,
            nav: true,
            controlsText: ['<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 28.5 2 15 15.5 1" stroke="#514D4C" stroke-width="2"/></svg>', '<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5 15 15 1.5 29" stroke="#514D4C" stroke-width="2"/></svg>'],
            slideBy: 'page',
            loop: true,
            navContainer: '.sliderGaleriaThumbs'
        });
      
        this.thumbnails = tns({
            container: '.sliderGaleriaThumbs',
            items: 8,
            lazyload: false,
            mouseDrag: true,
            controls: true,
            nav: true,
            controlsText: ['<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 28.5 2 15 15.5 1" stroke="#514D4C" stroke-width="2"/></svg>', '<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5 15 15 1.5 29" stroke="#514D4C" stroke-width="2"/></svg>'],
            loop: false,
            slideBy: 1,
            nav: false,
            responsive: {
                0: {
                    items: 4
                },
                700: {
                    items: 8
                },
                1700: {
                    items: 12
                }
            },
        });
      
        this.slider.events.on( 'indexChanged', evt => this.nextSlide( evt ));

    }

    nextSlide( evt ) {
        // hack beacause of strange idexes from tns slider
        const indexToGoTo = evt.index > this.slider.getInfo().slideCount ? 0 : evt.index - 1;

        this.thumbnails.goTo( indexToGoTo );
    }
    
}