import Cookies from 'js-cookie';

export default class CookiesModal {
    constructor() {

        this.btnRejeitarCookies = document.querySelector('#btnRejeitarCookies')
        this.btnPermitirCookies = document.querySelector('#btnPermitirCookies')

        this.modalCookies = document.querySelector('.modalCookiesFooter')
        
        this.cookiesSite = Cookies.get('cflsite')

        this.cookieUtmSource = document.querySelector('.menuFlutuante').dataset.sourceutm
        this.cookieUtmMedium = document.querySelector('.menuFlutuante').dataset.mediumutm
        this.cookieUtmCampaign = document.querySelector('.menuFlutuante').dataset.campaignutm
        
        Cookies.set('cflutmsource', this.cookieUtmSource, { expires: 365 });
        Cookies.set('cflutmmedium', this.cookieUtmMedium, { expires: 365 });
        Cookies.set('cflutmcampaign', this.cookieUtmCampaign, { expires: 365 });

        this.ready()
        
    }
    
    ready() {
        
        // console.log('Cookie site: ' + cookiesSite);
        if(!this.cookiesSite) {
            this.modalCookies.style.display = 'block'
        }

        //Aceitar Todos os Cookies
        this.btnPermitirCookies.addEventListener('click', () => {

            this.optInGoogleTracking();
            if(!Cookies.get('cflsite')) {
                Cookies.set('cflsite', true, { expires: 365 });
            }
            this.modalCookies.style.display = 'none'

        })

    }
    
    optOutGoogleTracking(){

        Cookie.set('_ga', undefined);
        Cookie.set('optOutGoogleTracking', true);
        // alert('disabling GA cookies');
        window.location.reload();
    }
    
    optInGoogleTracking(){
        Cookies.get('optOutGoogleTracking', false);
        // alert('enabling GA cookies');
        window.location.reload();
    }

    //Rejeitar Todos os Cookies
    rejeitarCookies() {

        this.btnRejeitarCookies.addEventListener('click', () => {
            this.optOutGoogleTracking();
            Cookies.remove('cflsite');
            this.modalCookies.style.display = 'block'
        })
        
    }

}