import { tns } from "tiny-slider";

export default class SliderEmpreendimentos {
    constructor() {
        this.ready();
    }

    ready() {

        const tnsCarousel = document.querySelectorAll(".sliderListEmpreendimentos");
        if (tnsCarousel && !tnsCarousel.length) return false;

        tnsCarousel.forEach(slider => {
            const infoItems = slider.dataset.items
            const itemsSlider = slider.querySelectorAll('.item')
            let totalItems = 1
            let gap = 0

            if(!infoItems) {
                if(itemsSlider.length > 1) {
                    totalItems = 2
                    gap = 20
                }
            }

            const sliderCurrent = tns({
                loop: false,
                container: slider,
                items: totalItems,
                gutter: gap,
                mouseDrag: true,
                controls: true,
                nav: true,
                autoHeight: false,
                controlsText: ['<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 28.5 2 15 15.5 1" stroke="#514D4C" stroke-width="2"/></svg>', '<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5 15 15 1.5 29" stroke="#514D4C" stroke-width="2"/></svg>'],
                responsive: {
                    0: {
                        items: 1,
                        gutter: 0
                    },
                    700: {
                        items: totalItems,
                        gutter: gap,
                    }
                },
                onInit: (sliderInstance) => {
                    sliderInstance.container.classList.remove("invisible");
                }
            });
        });

        
    }
}