export default class Video {
    constructor() {
        this.htmlWindow = document.querySelector('body')
        this.btnAbrirVideo = document.querySelector('#btnAbrirVideo')
        this.btnCloseModal = document.querySelector('#closeModalVideo')
        this.modalVideo = document.querySelector('.modalVideo')

        this.youtubeLocal = document.querySelector('.embed-youtube')

        this.ready();
    }

    ready() {

        // let YouTubeContainers = document.querySelectorAll(".embed-youtube");

        
        // Iterate over every YouTube container you may have
        // for (let i = 0; i < YouTubeContainers.length; i++) {
        //     let container = YouTubeContainers[i];
        //     let imageSource = container.dataset.videoImage; 

        //     // Load the Thumbnail Image asynchronously
        //     let image = new Image();
        //     image.src = imageSource;
        //     image.alt = "CFL - Assista ao vÃ­deo";
        //     image.addEventListener("load", function() {
        //         container.appendChild(image);
        //     });

        // }
        // When the user clicks on the container, load the embedded YouTube video
        this.btnAbrirVideo.addEventListener("click", () => {

            console.log(this.btnAbrirVideo.dataset.video)

            const videoId = this.btnAbrirVideo.dataset.video

            this.htmlWindow.classList.add('blockScroll')
            this.modalVideo.classList.add('active')

            let iframe = document.createElement( "iframe" );

            iframe.setAttribute("frameborder", "0");
            iframe.setAttribute("allowfullscreen", "");
            iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture");
            // Important: add the autoplay GET parameter, otherwise the user would need to click over the YouTube video again to play it 
            iframe.setAttribute("src", videoId);

            // Clear Thumbnail and load the YouTube iframe
            this.youtubeLocal.innerHTML = "";
            this.youtubeLocal.appendChild( iframe );

        });

        this.btnCloseModal.addEventListener('click', () => {
            this.htmlWindow.classList.remove('blockScroll')
            this.modalVideo.classList.remove('active')
            this.youtubeLocal.innerHTML = "";
        })
        
    }
}