import { tns } from "tiny-slider";

export default class SliderPlantas {
    constructor() {
        this.htmlWindow = document.querySelector('body')
        this.btnAbrirPlantas = document.querySelector('#btnAbrirPlantas')
        this.btnCloseModal = document.querySelector('#closeModalPlantas')
        this.modalPlanta = document.querySelector('.modalPlantas')

        this.ready();
    }

    ready() {

        this.btnAbrirPlantas.addEventListener('click', () => {
            this.htmlWindow.classList.add('blockScroll')
            this.modalPlanta.classList.add('active')
        })
        
        this.btnCloseModal.addEventListener('click', () => {
            this.htmlWindow.classList.remove('blockScroll')
            this.modalPlanta.classList.remove('active')
        })

        const tnsCarousel = document.querySelectorAll(".sliderPlantas");
        if (tnsCarousel && !tnsCarousel.length) return false;

        tnsCarousel.forEach(slider => {
            const sliderCurrent = tns({
                loop: false,
                container: slider,
                mouseDrag: true,
                controls: true,
                nav: true,
                autoHeight: false,
                items: 1,
                controlsText: ['<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 28.5 2 15 15.5 1" stroke="#514D4C" stroke-width="2"/></svg>', '<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5 15 15 1.5 29" stroke="#514D4C" stroke-width="2"/></svg>'],
                onInit: (sliderInstance) => {
                    sliderInstance.container.classList.remove("invisible");
                }
            });
        });

    }
    
}
