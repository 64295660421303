import axios from 'axios'
import * as VMasker from "vanilla-masker"

export default class Contato {
    constructor() {

        this.form = document.getElementById('formContato')
        this.btnSubmitForm = this.form.querySelector('#btnEnviarContato')
        
        this.fieldName = this.form.querySelector('#nome')
        this.fieldEmail = this.form.querySelector('#email')
        this.fieldPhone = this.form.querySelector('#telefone')
        this.fieldMessage = this.form.querySelector('#mensagem')
        
        this.errorForm = document.getElementById('erroContato');
        this.sucessoForm = document.getElementById('sucessoContato');

        this.telMask = ['(99)99999-9999'];

        this.telMask = ['(99)99999-9999'];

        this.events()

        this.url = window.location.origin;

    }

    serializeJSON(form) {
        // Create a new FormData object
        const formData = new FormData(form);
        // const csrftoken = this.getCookie('csrftoken');

        // Create an object to hold the name/value pairs
        const pairs = {};

        // Add each name/value pair to the object
        for (const [name, value] of formData) {
            if (name.endsWith('[]')) {
                const finalName = name.slice(0, -2);

                if (!pairs[finalName]) {
                    pairs[finalName] = [];
                }

                pairs[finalName].push(value);
            } else {
                pairs[name] = value;
            }
        }

        // Return the JSON string
        return JSON.stringify(pairs, null, 2);
    }
    
    sendForm() {
      
        const dataForm = new FormData(this.form); // console.log(dataForm)
        // console.log(dataForm)
    
        axios({
            method: "post",
            url: url + "/email/form_contato_lead.php",
            data: dataForm,
            headers: {"Content-Type": "multipart/form-data"}
        })
            .then((dataReturn) => {

                console.log('Retorno: ', dataReturn);
                let dadosEmail = parseInt(dataReturn.data);

                if(dadosEmail === 1) {

                    const token = 'bbd51f4d22c22d3dac72da6190b73988a4fc8b5f';
                    const newData = Object.fromEntries(dataForm.entries());
                    newData.idmidia = 0;
                    newData.campos_adicionais = {};
                    newData.campos_adicionais.mensagem = this.fieldMessage.value

                    axios({
                        method: "post",
                        url: "https://cfl.cvcrm.com.br/api/cvio/lead",
                        data: JSON.stringify(newData),
                        headers: {"token" : token}
                    })
                        .then((dataReturnLead) => {
                            if (dataReturnLead.data.codigo === 200 || dataReturnLead.data.codigo === 401) {
                                this.form.reset();
                                this.sucessoForm.style.display = 'block';
                            }
                        })
                        .catch(function (dataReturnLead) {
                            console.warn('ERRO')
                            console.log(dataReturnLead)
                        })

                }

            })
            .catch(function (data) {
                console.warn('ERRO')
                console.log(data)
            })

    }

    maskfields(masks, max, event) {
        var c = event.target;
        var v = c.value.replace(/\D/g, '');
        var m = c.value.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
    }
    
    validateField() {
        
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        let error = 0

        if(this.fieldName.value.length === 0) {
            error++
            this.fieldName.parentElement.classList.add('error-field')
        } else {
            this.fieldName.parentElement.classList.remove('error-field')
        }
        
        if(!this.fieldEmail.value.match(mailformat)) {
            error++
            this.fieldEmail.parentElement.classList.add('error-field')
            
        } else {
            this.fieldEmail.parentElement.classList.remove('error-field')
        }

        if(this.fieldPhone.value.length === 0) {
            error++
            this.fieldPhone.parentElement.classList.add('error-field')
        } else {
            this.fieldPhone.parentElement.classList.remove('error-field')
        }
        
        if(this.fieldMessage.value.length === 0) {
            error++
            this.fieldMessage.parentElement.classList.add('error-field')
        } else {
            this.fieldMessage.parentElement.classList.remove('error-field')
        }

        if(error === 0) {
            this.sendForm()
            this.errorForm.style.display = 'none';
        } else {
            this.errorForm.style.display = 'block';
        }

    }

    events() {

        VMasker(this.fieldPhone).maskPattern(this.telMask[0]);
        this.fieldPhone.addEventListener('input', this.maskfields.bind(undefined, this.telMask, 14), false);

        this.btnSubmitForm.addEventListener('click', this.validateField.bind(this))
        
    }

}