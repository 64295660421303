import Cookies from 'js-cookie';

export default class Faq {
    constructor() {

        this.perguntas = document.querySelectorAll('.pergunta')
        this.ready()
        
    }
    
    ready() {

        /* SLIDE UP */
        let slideUp = (target, duration=500) => {

            target.style.transitionProperty = 'height, margin, padding';
            target.style.transitionDuration = duration + 'ms';
            target.style.boxSizing = 'border-box';
            target.style.height = target.offsetHeight + 'px';
            target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            window.setTimeout( () => {
                target.style.display = 'none';
                target.style.removeProperty('height');
                target.style.removeProperty('padding-top');
                target.style.removeProperty('padding-bottom');
                target.style.removeProperty('margin-top');
                target.style.removeProperty('margin-bottom');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
                //alert("!");
            }, duration);
        }

        /* SLIDE DOWN */
        let slideDown = (target, duration=500) => {

            target.style.removeProperty('display');
            let display = window.getComputedStyle(target).display;
            if (display === 'none') display = 'block';
            target.style.display = display;
            let height = target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            target.offsetHeight;
            target.style.boxSizing = 'border-box';
            target.style.transitionProperty = "height, margin, padding";
            target.style.transitionDuration = duration + 'ms';
            target.style.height = height + 'px';
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            window.setTimeout( () => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
            }, duration);
        }

        /* TOOGLE */
        var slideToggle = (target, duration = 500) => {
            if (window.getComputedStyle(target).display === 'none') {
            return slideDown(target, duration);
            } else {
            return slideUp(target, duration);
            }
        }
        
        // console.log('Cookie site: ' + cookiesSite);
        if(this.perguntas) {
            this.perguntas.forEach((e) => {
                e.addEventListener('click', () => {
                    console.log(e.dataset.accordeon)
                    if(e.classList.contains('showAba')) {
                        e.classList.remove('showAba')
                        slideUp(e.querySelector('.conteudoAccordion'))
                    } else {
                        e.classList.add('showAba')
                        slideDown(e.querySelector('.conteudoAccordion'))
                    }
                })
            })
        }

    }

}