export default class MenuFlutuante {
    constructor() {

        this.btnModalTelefone = document.querySelector('#btnModalTelefone')
        this.btnModalContato = document.querySelector('#btnModalContato')
        this.btnModalWhatsApp = document.querySelector('#btnModalWhatsApp')
        this.modal = document.querySelectorAll('.modalSide')
        this.btnCloseModal = document.querySelectorAll('.closeModalSide')

        this.menuContatoSingleEmpreendimento = document.querySelector('#btnOpenContatoLateral')

        this.ready();
    }

    ready() {

        this.btnModalTelefone.addEventListener('click', () => {
            document.querySelector('#modalTelefone').classList.add('active')
            this.closeModal()
        })
        
        this.btnModalContato.addEventListener('click', () => {
            document.querySelector('#modalContato').classList.add('active')
            this.closeModal()
        })
        
        if(this.menuContatoSingleEmpreendimento) {
            this.menuContatoSingleEmpreendimento.addEventListener('click', () => {
                document.querySelector('#modalContato').classList.add('active')
                this.closeModal()
            })
        }
        
        this.btnModalWhatsApp.addEventListener('click', () => {
            document.querySelector('#modalWhatsApp').classList.add('active')
            this.closeModal()
        })
    
    }

    closeModal() {
        Array.from(this.btnCloseModal).forEach((button, index) => {
            button.addEventListener('click', () => {
                Array.from(this.modal).forEach((modal, index) => {
                    modal.classList.remove('active')
                })
            })
        })
    }
    
}