export default class Header {
    constructor() {

        this.header = document.querySelector('header')
        this.menuMobile = document.querySelector('#menuMobile')
        this.menu = document.querySelector('#menu')
        this.btnBuscaDesktop = document.querySelector('.btnSearchDesktop')
        this.btnBuscaMobile = document.querySelector('.btnSearchMobile')
        this.modalBusca = document.querySelector('.modalBusca')
        this.btnClosemodalBusca = document.querySelector('.modalBusca .closeModal')

        this.ready();
    }

    ready() {

        document.addEventListener('scroll', (e) => {
            let scrollBarPosition = window.scrollY
            if(scrollBarPosition > 0) {
                this.header.classList.add('scroll')
            } else {
                this.header.classList.remove('scroll')
            }
        })

		this.menuMobile.addEventListener("click", () => {
			this.menuMobile.classList.toggle("active");
			this.menu.classList.toggle("active");
		});

        if(this.btnBuscaDesktop) {
            this.btnBuscaDesktop.addEventListener("click", () => {
                this.modalBusca.classList.toggle('active')
            });
        }
        
        if(this.btnBuscaMobile) {
            this.btnBuscaMobile.addEventListener("click", () => {
                this.modalBusca.classList.toggle('active')
            });
        }
		
        this.btnClosemodalBusca.addEventListener("click", () => {
			this.modalBusca.classList.toggle('active')
		});
        
    }
}