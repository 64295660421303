import axios from 'axios';

export default class Filtros {
    constructor() {

        this.btnChangeSubFilter = document.querySelectorAll('.btnChangeSubFilter')
        this.imoveisItems = document.querySelectorAll('.imovelItem')

        this.ready();
    }

    ready() {

        //Filtro de Tipos
        const ddTipo = document.querySelector('#dropdown-tipo');
        if(ddTipo) {
            const linksTipo = ddTipo.querySelectorAll('.dropdown-list a');
            const spanTipo = ddTipo.querySelector('#textTipo');
    
            ddTipo.addEventListener('click', function() {
                this.classList.toggle('is-active');
            });
    
            linksTipo.forEach((element) => {
                element.addEventListener('click', function(evt) {
                    let langUrl;
                    if(language === 'es') {
                        langUrl = '/es/'
                    } else if(language === 'en') {
                        langUrl = '/en/'
                    } else {
                        langUrl = '/'
                    }
                    spanTipo.innerHTML = evt.currentTarget.textContent
                    const newRedirect = langUrl+'tipo-empreendimento/'+evt.currentTarget.dataset.value
                    window.location.href = newRedirect
                })
            })
        }

        //Filtro de Localização
        const ddLocalizacao = document.querySelector('#dropdown-localizacao');
        if(ddLocalizacao) {
            const linksLocalizacao = ddLocalizacao.querySelectorAll('.dropdown-list a');
            const spanLocalizacao = ddLocalizacao.querySelector('#textLocalizacao');
    
            ddLocalizacao.addEventListener('click', function() {
                this.classList.toggle('is-active');
            });
    
            linksLocalizacao.forEach((element) => {
                element.addEventListener('click', function(evt) {
                    let langUrl;
                    if(language === 'es') {
                        langUrl = '/es/'
                    } else if(language === 'en') {
                        langUrl = '/en/'
                    } else {
                        langUrl = '/'
                    }
                    spanLocalizacao.innerHTML = evt.currentTarget.textContent;
                    const newRedirect = langUrl+'localizacao/'+evt.currentTarget.dataset.value
                    window.location.href = newRedirect
                })
            })
        }
        
        //Select Suporte Pegar Empreendimentos por Cidade
        const ddSuporteCidades = document.querySelector('#dropdown-get-empreendimentos');
        if(ddSuporteCidades) {
            const linksCidades = ddSuporteCidades.querySelectorAll('.dropdown-list a');
            const spanCidades = ddSuporteCidades.querySelector('#textCidade');
    
            ddSuporteCidades.addEventListener('click', function() {
                this.classList.toggle('is-active');
            });
    
            linksCidades.forEach((element) => {
                element.addEventListener('click', function(evt) {
                    const valueData = evt.currentTarget.textContent;
                    spanCidades.innerHTML = valueData
                    console.log(evt.currentTarget.dataset.value)
                    // document.getElementById('cidade').value = evt.currentTarget.textContent
                    document.getElementById('cidade').value = evt.currentTarget.dataset.value

                    axios.get(url+'/ajax/get_produtos.php', {
                        params: {
                            cidade: valueData
                        }
                    })
                        .then((response) => {
                            // console.log(response.data)
                            const total = parseInt(response.data.total)
                            if(total > 0) {
                                const empreendimentos = response.data.produtos
                                const listEmpreendimentos = document.getElementById('listEmpreendimentosSuporte')
                                listEmpreendimentos.innerHTML = ''
                                empreendimentos.forEach((empreendimento, index) => {
                                    // console.log(empreendimento.nome)
                                    listEmpreendimentos.innerHTML += `
                                        <li>
                                            <a href="javascript:void(0);" data-value="${empreendimento.nome}">${empreendimento.nome}</a>
                                        </li>
                                    `
                                })
                                document.querySelector('#textEmpreendimento').innerHTML = 'Selecione'
                                //Select Produtos Por Cidade no Suporte
                                const ddSuporteEmpreendimentos = document.querySelector('#dropdown-empreendimentos');
                                if(ddSuporteEmpreendimentos) {
                                    const linksEmpreendimentos = ddSuporteEmpreendimentos.querySelectorAll('.dropdown-list a');
                                    const spanEmpreendimentos = ddSuporteEmpreendimentos.querySelector('#textEmpreendimento');
                            
                                    ddSuporteEmpreendimentos.addEventListener('click', function() {
                                        this.classList.toggle('is-active');
                                        linksEmpreendimentos.forEach((element) => {
                                            element.addEventListener('click', function(evt) {
                                                spanEmpreendimentos.innerHTML = evt.currentTarget.textContent;
                                                document.getElementById('empreendimento').value = evt.currentTarget.textContent
                                            })
                                        })
                                    });
                            
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })

                })
            })
        }

        //Change SUb Filter
        if(this.btnChangeSubFilter.length > 0) {
            this.btnChangeSubFilter.forEach(el => {
                el.addEventListener('click', () => {
                    this.btnChangeSubFilter.forEach(elremove => {
                        elremove.classList.remove('active')
                    })
                    el.classList.add('active')
                    const category = el.dataset.categoria
    
                    this.imoveisItems.forEach(element => {
                        if(element.classList.contains(category)) {
                            element.style.display = 'block'
                        } else {
                            element.style.display = 'none'
                        }
                    });
    
                })
            })
        }

    }

    templateListEmpreendimentos(name) {
        const template = `
            <li>
                <a href="javascript:void(0);" data-value="${name}">${name}</a>
            </li>
        `
        return template
    }
    
}