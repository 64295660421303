import { tns } from "tiny-slider";

export default class Slider {
    constructor() {
        this.ready();
    }

    ready() {

        const tnsCarousel = document.querySelectorAll(".sliderVitrine");
        if (tnsCarousel && !tnsCarousel.length) return false;

        tnsCarousel.forEach(slider => {
            const sliderCurrent = tns({
                loop: false,
                container: slider,
                mouseDrag: true,
                controls: true,
                nav: true,
                autoHeight: false,
                controlsText: ['<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 28.5 2 15 15.5 1" stroke="#514D4C" stroke-width="2"/></svg>', '<svg width="17" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5 15 15 1.5 29" stroke="#514D4C" stroke-width="2"/></svg>'],
                onInit: (sliderInstance) => {
                    sliderInstance.container.classList.remove("invisible");
                }
            });
        });

        
    }
}