import axios from 'axios';
import Forms from './form';
import Bouncer from 'formbouncerjs/src/js/bouncer/bouncer';

export default class Suporte {
    constructor() {

        this.form = document.querySelector('#formSuporte');
        this.formFields = this.form.querySelectorAll('input, select, textarea');
        
        this.errorForm = document.getElementById('erroSuporte');
        this.sucessoForm = document.getElementById('sucessoSuporte');

        this.produtoField = document.querySelector('#dropdown-empreendimentos');
        
        this.ready()

    }

    ready() {

        const cpfCnpjField = this.form.querySelectorAll(".cpfCnpjMask");
        const phoneField = this.form.querySelectorAll(".telMask");
        const dateField = this.form.querySelectorAll(".dateMask");
        Forms.masks(phoneField, dateField, cpfCnpjField, 'CPF');
        this.activeSelectTipoDocumento();

        this.populateEmpreendimentos();

        new Bouncer(`#formSuporte`, {
            fieldClass: 'error-field',
            disableSubmit: true,
            ...Forms.wrongMessages(),
        });

        document.addEventListener('bouncerShowError', (event) => {
            this.formFields.forEach(field => {
                if(field.classList.contains('error-field')) {
                    this.errorForm.classList.remove('noShow')
                    this.errorForm.scrollIntoView({ behavior: 'smooth' });
                }
            })

        }, false);

        this.form.addEventListener(
            'bouncerFormValid',
            (event) => {
                event.preventDefault();
                if (event.target.id == this.form.id) {
                    this.sendForm(event.target);
                }
            },
            false
        );

    }

    activeSelectTipoDocumento() {
        const ddSelectTipo = document.querySelector('#dropdown-tipo-documento');
        if(ddSelectTipo) {
            const linksSelectTipo = ddSelectTipo.querySelectorAll('.dropdown-list a');
            const spanSelectTipo = document.querySelector('#textTipo');
    
            ddSelectTipo.addEventListener('click', () => {
                ddSelectTipo.classList.toggle('is-active');
            });
    
            linksSelectTipo.forEach((element) => {
                element.addEventListener('click', (evt) => {
                    spanSelectTipo.innerHTML = evt.currentTarget.textContent
                    const cpfCnpjField = this.form.querySelectorAll(".cpfCnpjMask");
                    const phoneField = this.form.querySelectorAll(".telMask");
                    const dateField = this.form.querySelectorAll(".dateMask");
                    Forms.masks(phoneField, dateField, cpfCnpjField, evt.currentTarget.dataset.value);
                })
            })
        }
    }

    populateEmpreendimentos() {
        axios.get(url + '/ajax/get_empreendimentos.php')
            .then(response => {
                const empreendimentos = response.data.obras;
                const dropdownList = this.form.querySelector('#dropdown-get-empreendimentos .dropdown-list');
                dropdownList.innerHTML = '';
                empreendimentos.sort((a, b) => a.nome.localeCompare(b.nome));
                empreendimentos.forEach(empreendimento => {
                    dropdownList.appendChild(this.templateSelect(empreendimento.id, empreendimento.nome));
                });
                this.activeSelectEmpreendimentos();
            })
            .catch(error => {
                console.error('Erro ao buscar empreendimentos:', error);
            });
    }

    activeSelectEmpreendimentos() {
        const ddSelectEmpreendimento = document.querySelector('#dropdown-get-empreendimentos');
        if(ddSelectEmpreendimento) {
            const linksSelectEmpreendimento = ddSelectEmpreendimento.querySelectorAll('.dropdown-list a');
            const spanSelectEmpreendimento = document.querySelector('#textEmpreendimento');
    
            ddSelectEmpreendimento.addEventListener('click', () => {
                ddSelectEmpreendimento.classList.toggle('is-active');
            });
    
            linksSelectEmpreendimento.forEach((element) => {
                element.addEventListener('click', (evt) => {
                    spanSelectEmpreendimento.innerHTML = evt.currentTarget.textContent
                    this.form.querySelector('#idLocal').value = evt.currentTarget.dataset.value
                    this.populateUnidades(evt.currentTarget.dataset.value)
                })
            })
        }
    }

    populateUnidades(idObra) {
        axios.get(url + '/ajax/get_unidades.php?idObra=' + idObra)
            .then(response => {
                const locais = response.data.locais;
                console.log(locais);
    
                const dropdownList = this.form.querySelector('#dropdown-get-unidades .dropdown-list');
                dropdownList.innerHTML = '';
    
                // Ordenando corretamente os codLocal numericamente
                locais.sort((a, b) => {
                    const numA = a.codLocal.split('.').map(Number);
                    const numB = b.codLocal.split('.').map(Number);
    
                    for (let i = 0; i < Math.max(numA.length, numB.length); i++) {
                        const valA = numA[i] || 0;
                        const valB = numB[i] || 0;
                        if (valA !== valB) return valA - valB;
                    }
                    return 0;
                });
    
                // Populando dropdown
                locais.forEach(local => {
                    dropdownList.appendChild(this.templateSelect(local.id, local.nome));
                });
    
                this.activeSelectUnidades();
            })
            .catch(error => {
                console.error('Erro ao buscar unidades do empreendimento:', error);
            });
    }
    

    activeSelectUnidades() {
        const ddSelectUnidades = document.querySelector('#dropdown-get-unidades');
        if(ddSelectUnidades) {
            const linksSelectUnidades = ddSelectUnidades.querySelectorAll('.dropdown-list a');
            const spanSelectUnidades = document.querySelector('#textUnidades');

            spanSelectUnidades.innerHTML = 'Selecione a unidade';
    
            ddSelectUnidades.addEventListener('click', () => {
                ddSelectUnidades.classList.toggle('is-active');
            });
    
            linksSelectUnidades.forEach((element) => {
                element.addEventListener('click', (evt) => {
                    spanSelectUnidades.innerHTML = evt.currentTarget.textContent
                    this.form.querySelector('#idUnidade').value = evt.currentTarget.dataset.value
                })
            })
        }
    }

    templateSelect(id, nome) {
        const listItem = document.createElement('li');
        const link = document.createElement('a');
        link.href = 'javascript:void(0);';
        link.dataset.value = id;
        link.textContent = nome;
        listItem.appendChild(link);
        return listItem;
    }

    sendForm(form) {
        const formData = new FormData(form);
        formData.append('dataAberturaSolicitacao', new Date().toISOString());

        axios.post(url + '/ajax/abre_solicitacao.php', formData)
            .then(response => {
                // console.log(response)
                if (response.data.idRequest) {
                    // Handle success response
                    console.log('Formulário enviado com sucesso:', response.data);
                    // Optionally, you can reset the form or show a success message
                    form.reset();
                    this.errorForm.classList.add('noShow');
                    this.sucessoForm.classList.remove('noShow');
                } else {
                    // Handle error response
                    console.error('Erro ao enviar formulário:', response.data.message);
                    this.sucessoForm.classList.add('noShow');
                    this.errorForm.classList.remove('noShow');
                }
            })
            .catch(error => {
                console.error('Erro ao enviar formulário:', error);
                this.errorForm.classList.remove('noShow');
            });
    }
}