export default class Idioma {
    constructor() {
        this.ready();
    }

    ready() {

        //Seletor de Idiomas
        const ddIdioma = document.querySelector('#dropdown-idioma');
        if(ddIdioma) {
            // debugger
            const linksIdioma = ddIdioma.querySelectorAll('.dropdown-list a');
            const spanIdioma = ddIdioma.querySelector('#textidioma');
    
            ddIdioma.addEventListener('click', function() {
                this.classList.toggle('is-active');
            });
    
            linksIdioma.forEach((element) => {
                element.addEventListener('click', function(evt) {
                    spanIdioma.innerHTML = evt.currentTarget.textContent
                })
            })
        }
        
        //Seletor de Idiomas Mobile
        const ddIdiomaMobile = document.querySelector('#dropdown-idioma-mobile');
        if(ddIdiomaMobile) {
            const linksIdiomaMobile = ddIdiomaMobile.querySelectorAll('.dropdown-list a');
            const spanIdiomaMobile = ddIdiomaMobile.querySelector('#textidiomaMobile');
    
            ddIdiomaMobile.addEventListener('click', function() {
                this.classList.toggle('is-active');
            });
    
            linksIdiomaMobile.forEach((element) => {
                element.addEventListener('click', function(evt) {
                    spanIdiomaMobile.innerHTML = evt.currentTarget.textContent
                })
            })
        }

    }
    
}