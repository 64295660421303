export default class Tabs {
    constructor() {

        this.tabsContent = document.querySelectorAll('.tabs')
        this.ready();
    }

    ready() {

        
        Array.from(this.tabsContent).forEach((tabSection, index) => {
            
            //Instancia Menus e Tabs
            const menuTabSection = tabSection.querySelectorAll('.tabMenu')
            const tabsTabItem = tabSection.querySelectorAll('.tab')
            
            Array.from(menuTabSection).forEach((element, index) => {

                if(index === 0) {
                    element.classList.add('active')
                    const thisTab = element.dataset.tab
                    document.querySelector('#tab-'+thisTab).classList.add('active')
                }
                
                element.addEventListener('click', () => {

                    //Desmarca os menus todos das abas atuais
                    menuTabSection.forEach((el) => {
                        el.classList.remove('active')
                    })

                    //Remove as tabs atuais
                    tabsTabItem.forEach((el) => {
                        el.classList.remove('active')
                    })

                    //Marca o menu atual
                    element.classList.add('active')
                    const thisTab = element.dataset.tab

                    //Marca a aba selecionada
                    document.querySelector('#tab-'+thisTab).classList.add('active')
                    
                })
            })

        })


    }
    
}