"use strict";

//Modules

//Components
import Slider from './components/slider';
import Servicos from './components/servicos';
import Header from './components/header';
import Contato from './components/form_contato';
import SliderNoticias from './components/slider-noticias';
import SliderPlantas from './components/slider-plantas';
import SliderGaleria from './components/slider-galeria';
import SliderNoticiasDestaque from './components/slider-noticias-destaque';
import Tabs from './components/tabs';
import SliderEmpreendimentos from './components/slider-empreendimentos';
import Filtros from './components/filtros';
import Idioma from './components/idioma';
import MenuFlutuante from './components/menuFlutuante';
import Video from './components/video';
import Suporte from './components/form_suporte';
import ContatoLateral from './components/form_contato_lateral';
import CookiesModal from './components/cookies';
import Faq from './components/accordeon';
import TelefoneContatoLateral from './components/form_telefone_lateral';
import WhatsAppContatoLateral from './components/form_whatsapp_lateral';

export default class App {
    constructor(){

        new Header()
        new MenuFlutuante()
        new CookiesModal()

        const SelectsElement = document.querySelector('.filtros')
        SelectsElement ? new Filtros() : null
        
        const IdiomasElement = document.querySelector('#dropdown-idioma')
        IdiomasElement ? new Idioma() : null

        const SliderVitrineElement = document.querySelector('.vitrine')
        SliderVitrineElement ?  new Slider() : null

        const SliderNoticiasElement = document.querySelector('.sliderVejaTambem')
        SliderNoticiasElement ?  new SliderNoticias() : null

        const SliderPlantasElement = document.querySelector('.plantas')
        SliderPlantasElement ? new SliderPlantas() : null

        const SliderGaleriaElement = document.querySelector('.sliderGaleria')
        SliderGaleriaElement ? new SliderGaleria() : null

        const SliderNoticiasDestaqueElement = document.querySelector('.sliderNoticiasDestaque')
        SliderNoticiasDestaqueElement ? new SliderNoticiasDestaque() : null

        const TabsElement = document.querySelector('.tabs')
        TabsElement ? new Tabs() : null

        const SliderEmpreendimentosElement = document.querySelectorAll('.sliderEmpreendimentos')
        SliderEmpreendimentosElement ? new SliderEmpreendimentos() : null

        const VideoModuleElement = document.querySelector('.video')
        VideoModuleElement ?  new Video() : null

        const SuporteModuleElement = document.querySelector('#formSuporte')
        SuporteModuleElement ? new Suporte() : null
        
        const ContactModuleElement = document.querySelector('#formContato')
        ContactModuleElement ? new Contato() : null
        
        const FaqModuleElement = document.querySelector('.page-relacionamento')
        FaqModuleElement ? new Faq() : null
        
        const ContactSideModuleElement = document.querySelector('#formContatoLateral')
        ContactSideModuleElement ? new ContatoLateral() : null

        const ContactSideTelefoneModuleElement = document.querySelector('#modalTelefone')
        ContactSideTelefoneModuleElement ? new TelefoneContatoLateral() : null
        
        const ContactSideWhatsAppModuleElement = document.querySelector('#modalWhatsApp')
        ContactSideWhatsAppModuleElement ? new WhatsAppContatoLateral() : null

    }
}

new App();